import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import '/client/app/styles/text.scss';
import LoginFormContainer from '../../components/forms/loginForm/loginFormContainer';

export default function SignInView(props) {
	useEffect(() => {
		document.title = 'pfyt - sign in';

		return function cleanup() {
			document.title = 'pfyt';
		};
	});

	const { location } = props;
	const { dest } = QueryString.parse(location.search);
	return (
		<>
			<div className="mainTitle">sign in</div>
			<LoginFormContainer destination={dest} />
		</>
	);
}

SignInView.propTypes = {
	location: PropTypes.shape({
		search: PropTypes.string,
	}).isRequired,
};
