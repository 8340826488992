import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginFailure } from '/common/reducers/globalUserStateReducer/globalUserStateReducerActions';
import {
	loginFormLoginAttempt,
	loginFormLoginSuccess,
	loginFormLogoutSuccess,
	loginFormLoginFailure,
	loginFormValuesUpdated,
} from '/common/reducers/loginFormReducer/loginFormReducerActions';
import { authenticate } from '/client/app/api/backend';
import Input from '/client/app/components/common/inputs/input/input';
import '/client/app/styles/controls.scss';
import './loginForm.scss';

class LoginForm extends React.Component {
	constructor(props) {
		super(props);

		this.attemptLogin = this.attemptLogin.bind(this);
		this.onEmailInput = this.onEmailInput.bind(this);
		this.onPasswordInput = this.onPasswordInput.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.createAccount = this.createAccount.bind(this);
	}

	onEmailInput(e) {
		const { loginFormValuesUpdated: loginFormValuesUpdatedProp, password } = this.props;
		loginFormValuesUpdatedProp(e.target.value, password);
	}

	onPasswordInput(e) {
		const { loginFormValuesUpdated: loginFormValuesUpdatedProp, email } = this.props;
		loginFormValuesUpdatedProp(email, e.target.value);
	}

	resetPassword(e) {
		e.preventDefault();
		const { email } = this.props;
		window.location.href = `/resetPassword?email=${encodeURIComponent(email)}`;
		return false;
	}

	createAccount(e) {
		e.preventDefault();
		const { destination } = this.props;
		window.location.href = `/createAccount?dest=${encodeURIComponent(destination)}`;
		return false;
	}

	attemptLogin(e) {
		e.preventDefault();

		const {
			password,
			email,
			loginFailure: onLoginFailure,
			loginFormLoginAttempt: loginFormLoginAttemptProp,
			destination,
		} = this.props;
		const trimmedEmail = email.trim();

		loginFormLoginAttemptProp();

		authenticate(trimmedEmail, password)
			.then((user) => {
				if (user !== null) {
					window.location.href = destination;
				} else {
					onLoginFailure('general login failure.');
				}
			})
			.catch((error) => {
				onLoginFailure(error.message.replace(/^Error: /, ''));
			});

		return false;
	}

	render() {
		const { email, password, error, submitButtonText, submitDisabled } = this.props;

		return (
			<form className="loginForm" name="loginForm" onSubmit={this.attemptLogin}>
				<label htmlFor="email">username or email</label>
				<br />
				<Input
					type="text"
					name="email"
					id="email"
					placeholder="username or email"
					value={email}
					onChange={this.onEmailInput}
					className="loginFormField"
					autoFocus
					required
				/>
				<br />
				<label htmlFor="password">password</label>
				<br />
				<Input
					type="password"
					id="password"
					name="password"
					placeholder="password"
					value={password}
					onChange={this.onPasswordInput}
					className="loginFormField"
					required
				/>
				<br />
				<button className="standardButton loginButton" name="loginButton" type="submit" disabled={submitDisabled}>
					<b>{submitButtonText}</b>
				</button>
				<button type="button" className="standardButton loginButton" name="resetPasswordButton" onClick={this.resetPassword}>
					reset password
				</button>
				<button type="button" className="standardButton loginButton" name="createAccountButton" onClick={this.createAccount}>
					create account
				</button>
				<p className="loginErrorLabel">{error.toLowerCase()}</p>
			</form>
		);
	}
}

LoginForm.propTypes = {
	email: PropTypes.string,
	password: PropTypes.string,
	submitButtonText: PropTypes.string.isRequired,
	submitDisabled: PropTypes.bool,
	error: PropTypes.string,
	loginFailure: PropTypes.func.isRequired,
	loginFormLoginAttempt: PropTypes.func.isRequired,
	loginFormValuesUpdated: PropTypes.func.isRequired,
	destination: PropTypes.string,
};

LoginForm.defaultProps = {
	email: '',
	password: '',
	submitDisabled: true,
	error: null,
	destination: 'subscribed',
};

const mapDispatchToProps = (dispatch) => ({
	loginFailure: (error) => dispatch(loginFailure(error)),
	loginFormLoginAttempt: () => dispatch(loginFormLoginAttempt()),
	loginFormLoginSuccess: () => dispatch(loginFormLoginSuccess()),
	loginFormLogoutSuccess: () => dispatch(loginFormLogoutSuccess()),
	loginFormLoginFailure: (error) => dispatch(loginFormLoginFailure(error)),
	loginFormValuesUpdated: (email, password) => dispatch(loginFormValuesUpdated(email, password)),
});

export default connect(null, mapDispatchToProps)(LoginForm);
